import React from 'react';
import {SnackbarProvider, SnackbarProviderProps} from "notistack";
import {AsporComponent} from "@aspor/aspor-react";
import {NotificationService} from "./notification.service";

export type NotificationServiceProviderProps = SnackbarProviderProps & {
  children?: any
}

export type NotificationServiceProviderState = {

}

export default class NotificationServiceProvider extends AsporComponent<NotificationServiceProviderProps, NotificationServiceProviderState> {

  readonly ref = React.createRef<SnackbarProvider>()

  componentDidMount() {
    this.app.registerService(new NotificationService((notification)=>{
      this.ref.current?.enqueueSnackbar(notification.text,{
        variant: notification.variant
      })
    }))

   /*
    this.ref.current?.enqueueSnackbar("Careful — you have unsaved changes!",{
      persist: true,
      anchorOrigin: {vertical: "bottom",horizontal: "center"},
      content: ()=>(
          <Paper sx={{borderRadius: 1.5}}>
            <HorizontalBox padding={2}>
              <Typography>Careful — you have unsaved changes!</Typography>
              <div>
                <Button variant="text" color="secondary">Reset</Button>
                <Button variant="contained" color="success">Save Changes</Button>
              </div>
            </HorizontalBox>
          </Paper>
      )
    })
    */
  }

  componentWillUnmount() {
    //this.app.unregisterService(RouteService)
  }

  render() {
    return  <SnackbarProvider ref={this.ref} maxSnack={5} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} {...this.props}>
      {this.props.children}
    </SnackbarProvider>

  }

}
