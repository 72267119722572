import * as React from "react";
import {useApplication} from "@aspor/aspor-react";
import {
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Typography
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import AuthenticationService from "@aspor/aspor-react/system/service/authentication/authentication.service";
import {FallbackAvatar, FlexBox } from "@fluxusui/fluxusui-base";
import {useNavigate} from "react-router-dom";
import EventNoteIcon from '@mui/icons-material/EventNote';

interface ProfilePopoverProps {
    accountAnchorTarget: any,
    onClose: ()=>void,
}

export default function ProfilePopover(props : ProfilePopoverProps){
    const app = useApplication()
    const navigate = useNavigate()

    const handleLogout = () => app.service(AuthenticationService).logout();
    const handleOpenAccounts = () => {
        navigate("/schedules");
        props.onClose();
    }

    const createListItem = (icon : any, text : string,action?: ()=>void) => {
        return <ListItemButton onClick={action}>
            <ListItemIcon sx={{minWidth: (theme)=>theme.spacing(5)}}>{icon}</ListItemIcon>
            <ListItemText primary={text}/>
        </ListItemButton>
    }

    if(!app.isAuthenticated) return <React.Fragment />
    return (
        <Popover
            style={{zIndex: 1500}}
            open={props.accountAnchorTarget != null}
            anchorEl={props.accountAnchorTarget}
            onClose={props.onClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            transformOrigin={{vertical: 'center', horizontal: 'right'}}>

            <FlexBox alignItems="center" padding={1}>
                <FallbackAvatar size={35} src={app.user.avatarUrl} name={app.user.username}/>
                <FlexBox flexDirection="column" justifyContent="center" paddingLeft={1}>
                    <FlexBox>
                        <Typography variant="subtitle2" fontWeight="bold">{app.user.username}</Typography>
                        <Typography variant="subtitle2" >#{app.user.discriminator}</Typography>
                    </FlexBox>
                    <Typography variant="caption" >{app.user.email}</Typography>
                </FlexBox>
            </FlexBox>

            <Divider />

            <List dense={true}>
                {createListItem(<EventNoteIcon />,"Meine Kalender",handleOpenAccounts)}
                {createListItem(<LogoutIcon />,"Ausloggen",handleLogout)}
            </List>

        </Popover>
    )
}
