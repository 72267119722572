import React from 'react';
import {Box, Paper} from "@mui/material";
import {Schedule, ScheduleEntryValue} from "../../../entities";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

export type ScheduleEntryRowProps = {
    schedule?: Schedule
    values: ScheduleEntryValue[]
}

export default function ScheduleEntryRow(props: ScheduleEntryRowProps) {
    return <Box sx={{height: 40, marginBottom: "1px", minWidth: "100%", width: "fit-content"}} display="flex" alignItems="center">
        {props.schedule?.options.map((option, index)=>
            {
                const value = props.values.find(v => v.scheduleOptionId === option.id);
                return <Paper key={index} sx={{height: "100%", display: "flex", alignItems:"center", justifyContent: "center", width: 40, marginRight: "1px"}}>
                    {value?.availability === 0 && (<TaskAltIcon sx={{color: "#66bb6a"}} />)}
                    {value?.availability === 1 && (<HelpOutlineIcon sx={{color: "#ffa726"}} />)}
                    {value?.availability === 2 && (<HighlightOffIcon sx={{color: "#f44336"}} />)}
                    {(!value || value?.availability > 2) && (<PanoramaFishEyeIcon sx={{color: "#29b6f6"}} />)}
                </Paper>
            }
        )}
    </Box>

}