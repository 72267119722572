import React from "react";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText, IconButton,
} from "@mui/material";
import {Schedule} from "../entities";
import {
    IconDialogTitle,
    Form,
    TextInput,
    Space,
    SwitchInput,
    InputLabel,
    ControlledDialog,
    ActionButton,
    useForm,
    useFormProperty,
    Required,
    MinLength,
    FormHelper, DateTimeInput, ValidationResultCollection
} from "@fluxusui/fluxusui-base";
import {DialogState} from "@fluxusui/fluxusui-base/hooks/useDialogHook";
import EventNoteIcon from '@mui/icons-material/EventNote';
import {NotificationService} from "../services/notification/notification.service";
import {useApplication} from "@aspor/aspor-react";
import CloseIcon from '@mui/icons-material/Close';
import {RequiresOptions, ValidDates} from "../validators";
import {DataService} from "../services/data/data.service";

export type BoardModifyDialogProps = {
    state: DialogState,
    schedule?: Schedule,
    onReload: ()=>void
}

function createDefault(){
    return {
        name: "",
        description: "",
        morePeopleEnabled: true,
        options: [
            null,
            null
        ]
    }
}

export default function ScheduleModifyDialog(props : BoardModifyDialogProps){
    const app = useApplication()

    const create = props.schedule === undefined;
    
    const [form, completeForm] = useForm(props.schedule??createDefault(),undefined,[props.schedule]);

    const name = useFormProperty(form,"name",[Required(),MinLength(3)])
    const description = useFormProperty(form,"description")
    const morePeopleEnabled = useFormProperty(form,"morePeopleEnabled")
    const options = useFormProperty<any[]>(form,"options",[RequiresOptions(), ValidDates()])

    const handleOptionAdd = () => {
        options.setValue([...options.value, null])
    }

    const handleRemove = (index : number) => () => {
        let newOptions = [...options.value]
        newOptions.splice(index,1)
        options.setValue(newOptions)
    }

    const handleChange = (index : number) => (value: Date) => {
        let newOptions = [...options.value]
        newOptions[index] = {...newOptions[index],date: value};
        options.setValue(newOptions)
    }

    const handleSubmit = () => {
        return FormHelper.performValidation([name, description, morePeopleEnabled, options],(result : ValidationResultCollection)=>{
            return new Promise((resolve, reject)=>{
                if(create){
                    app.service(DataService).schedules().post(form.getObject())
                        .now().then(resolve).catch(reject)
                }else{
                    let delta = form.getDelta();
                    let directDelta = {...delta}
                    delete directDelta["options"]

                    let batch =  app.service(DataService).startBatch();
                    if(Object.keys(directDelta).length > 0){
                        batch.add(app.service(DataService).schedules(props.schedule!.id).patch(directDelta))
                    }

                    if(delta.options){
                        let originalOptions = form.getOriginal("options");
                        let options = delta.options;
                        for(let option of options){
                            if(option.id){
                                let index = options.findIndex((o : any)=>o.id === option.id);
                                if(index >= 0){
                                    let originalOption = originalOptions[originalOptions.findIndex((o : any)=>o.id === option.id)]
                                    if(originalOption){
                                        if(originalOption.date !== option.date){
                                            batch.add(app.service(DataService).schedules(props.schedule!.id).options(option.id).patch({date: option.date}))
                                        }
                                        continue;
                                    }
                                }
                            }
                            batch.add(app.service(DataService).schedules(props.schedule!.id).options().post({date: option.date}))
                        }
                        for(let option of originalOptions){
                            if(options.findIndex((o : any)=>o.id === option.id) < 0){
                                batch.add(app.service(DataService).schedules(props.schedule!.id).options(option.id).delete())
                            }
                        }
                    }

                    if(batch.requests().length > 0 ) batch.execute().then(resolve).catch(reject)
                    else resolve({});
                }
            })
        })
    }

    const handleSubmitComplete = () => {
        completeForm()
        props.state.close();
        props.onReload();
    }

    const handleSubmitFailure = (error : any) => {
        if(error.message !== "Validation error") app.service(NotificationService).handleError(error);
    }

    return <ControlledDialog state={props.state} >
        <IconDialogTitle title={create?"Neuen Kalender erstellen":"Kalender bearbeiten"} icon={EventNoteIcon}/>

        <DialogContent>

            <DialogContentText>
                Konfiguriere deinen eigenen Kalender, um das perfekte Datum mit deinen Freunden und Familie zu finden.
            </DialogContentText>

            <Form marginTop={2}>
                <TextInput label="Name" required maxLength={64}
                           value={name.value}
                           onChange={name.handleChange}
                           error={name.error}
                />

                <TextInput label="Beschreibung" multiline rows={3} maxLength={500}
                           value={description.value}
                           onChange={description.handleChange}
                           error={description.error}/>

                <SwitchInput label="Kann weitere Personen mitbringen (Freunde, Familie, etc.)" required
                             value={morePeopleEnabled.value === true}
                             onChange={morePeopleEnabled.setValue}
                             error={morePeopleEnabled.error}/>

                <InputLabel text="Optionen / Daten" required error={options.error}/>
                <Box>

                    {options.value.map((option : any, index: number)=> {
                        return <React.Fragment key={index}>
                            <Box display="flex" alignItems="center">
                                <Box sx={{flex: 1, marginRight: 1}} >
                                    <DateTimeInput value={option?.date} onChange={handleChange(index)} />
                                </Box>
                                <IconButton size="small" disabled={options.value.length <= 2} onClick={handleRemove(index)}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Box>
                            <Space spacing={0.25} />
                        </React.Fragment>
                    })}
                    <Button onClick={handleOptionAdd}>Option hinzufügen</Button>
                </Box>

            </Form>

        </DialogContent>

        <DialogActions>
            <Button onClick={props.state.close}>Abbrechen</Button>
            <ActionButton variant="contained"
                          onClick={handleSubmit}
                          disabled={!FormHelper.hasChangedAndIsValid([name,description, morePeopleEnabled, options])}
                          onComplete={handleSubmitComplete}
                          onFailure={handleSubmitFailure} >{create?"Erstellen":"Speichern"}</ActionButton>
        </DialogActions>
        
    </ControlledDialog>
}

