import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import {Application, AsporApplication, AuthenticationService} from "@aspor/aspor-react";
import {CssBaseline} from "@mui/material";
import {BrowserRouter } from 'react-router-dom';
import {DataService} from "./services/data/data.service";
import PretronicAuthenticationService from "./services/authentication/pretronic.authorization.service";
import NotificationServiceProvider from "./services/notification/NotificationServiceProvider";
import {RouteServiceInjector} from "./services/route/RouteServiceInjector";
import {UserService} from "./services/user/user.service";
import DialogServiceProvider from "./services/dialog/DialogServiceProvider";
import './localization'
import FluxusThemeProvider from '@fluxusui/fluxusui-base/components/FluxusThemeProvider';
import {GlobalWindowTitleConfig} from "@fluxusui/fluxusui-base";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from 'date-fns/locale/de';

GlobalWindowTitleConfig.SUFFIX = " | Eventrice"

if(navigator.serviceWorker){
    navigator.serviceWorker.register("/sw.js",{scope: '/'})
        .then((registration)=>{
            console.log("Service worker has been registered",registration)
        })
        .catch((error)=>console.log("Could not register service worker",error));
}

let application = Application.new();

application.registerService(AuthenticationService, PretronicAuthenticationService);
application.registerService(DataService, DataService);
application.registerService(UserService, UserService);

const root = ReactDOMClient.createRoot(document.getElementById('root')!)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AsporApplication application={application}>
                <FluxusThemeProvider mode="light" primary="#FF3719" secondary="#4F1F90" >
                   <CssBaseline />
                   <NotificationServiceProvider>
                       <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                           <RouteServiceInjector>
                               <DialogServiceProvider />
                               <App />
                           </RouteServiceInjector>
                       </LocalizationProvider>
                   </NotificationServiceProvider>
               </FluxusThemeProvider>
            </AsporApplication>
        </BrowserRouter>
    </React.StrictMode>
)
