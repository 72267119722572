import React from 'react';
import {Box, Button, IconButton, Typography, useTheme} from "@mui/material";
import {FallbackAvatar, TextInput} from "@fluxusui/fluxusui-base";
import {Schedule} from "../../../../entities";
import ScheduleEntryRow from "../../entry/ScheduleEntryRow";
import {useApplication} from "@aspor/aspor-react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {FormProperty} from "@fluxusui/fluxusui-base/libraries/form/hooks/useFormProperty.hook";
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';

export type ControllerMorePeopleStepProps = {
  schedule?: Schedule,
  name: FormProperty<string>
  values: FormProperty<any>
  amount: FormProperty<number>,
  onNext: () =>void,
  controls?: boolean
}

export default function ControllerMorePeopleStep(props: ControllerMorePeopleStepProps) {
  const app = useApplication()

  const theme = useTheme()
  const selectedColor = theme.palette.action.selected+" !important";

  const handleSelect = (amount: number) => () => {
    props.amount.setValue(amount)
    if(amount < 3 && props.controls) props.onNext();
  }


  return <React.Fragment>
    <Box sx={{ minWidth: 180, maxWidth: 180}} marginRight={1}>
      <Box padding={1} paddingTop={0.5}>
        <Box display="flex" alignItems="center">
          <FallbackAvatar size={30} src={app.isAuthenticated?app.user.avatarUrl:undefined} />
          <TextInput sx={{marginLeft: 1}} variant="outlined" value={props.name.value} error={props.name.error} onChange={props.name.handleChange}  />
        </Box>
      </Box>

      <Box paddingLeft={2} marginTop={1} display="flex" flexDirection="column">
        <Typography variant="subtitle2" fontWeight="bold" >Wie viele sind sie? </Typography>
        <Box marginTop={1}>
          <Button variant="outlined" size="small" onClick={handleSelect(1)} sx={{marginRight: 1, marginBottom: 1, background: props.amount.value === 1 ? selectedColor : undefined}}>
            <PersonIcon fontSize="small"/>
          </Button>
          <Button variant="outlined" size="small" onClick={handleSelect(2)} sx={{marginBottom: 1, background: props.amount.value === 2 ? selectedColor : undefined}}>
            <PeopleIcon fontSize="small"/>
          </Button>
          <Button variant="outlined" size="small" onClick={handleSelect(3)} sx={{background: props.amount.value > 2 ? selectedColor : undefined}}>
            <GroupsIcon fontSize="small"/>
          </Button>
        </Box>
      </Box>

    </Box>

    <Box sx={{ flex: 1, width: 1, minHeight: "100%", height: "fit-content", overflowX: "overlay"}} className="scroll-invisible" id="schedule-options-foot" display="flex" flexDirection="column">

      <ScheduleEntryRow schedule={props.schedule} values={props.values.value} />

      {props.amount.value >= 3 && (
          <Box marginTop={1} sx={{ marginBottom: "1px", width: (props.schedule?.options?.length??2)*41, maxWidth: "100%", flex: 1}} display="flex" alignItems="center" justifyContent="center">

            <Box display="flex" flexDirection="column">
              <IconButton size="small" disabled={props.amount.value > 10} onClick={()=>props.amount.setValue(props.amount.value+1)}>
                <AddIcon fontSize="small"/>
              </IconButton>

              <Typography fontWeight="bold" variant="subtitle2" sx={{color: "#0288d1"}}>{props.amount.value} Personen</Typography>

              <IconButton size="small" disabled={props.amount.value === 1} onClick={()=>props.amount.setValue(props.amount.value-1)}>
                <RemoveIcon fontSize="small"/>
              </IconButton>
            </Box>

            {props.controls && (<Button sx={{marginLeft: 2}} variant="outlined" onClick={props.onNext}>Ok</Button>)}

          </Box>
      )}

    </Box>
  </React.Fragment>

}