import React from 'react';
import {Box, Typography} from "@mui/material";
import {FallbackAvatar, TextInput} from "@fluxusui/fluxusui-base";
import {Schedule} from "../../../../entities";
import ScheduleEntryButtons from '../../entry/ScheduleEntryButtons';
import ScheduleEntryRow from "../../entry/ScheduleEntryRow";
import {useApplication} from "@aspor/aspor-react";
import {FormProperty} from "@fluxusui/fluxusui-base/libraries/form/hooks/useFormProperty.hook";

export type ControllerAvailabilityStepProps = {
  schedule?: Schedule,
  name: FormProperty<string>
  values: FormProperty<any>
  handleValuesChange: (optionId: string)=> (action: number)=>void
}

export default function ControllerAvailabilityStep(props: ControllerAvailabilityStepProps) {
  const app = useApplication()

  return <React.Fragment>
    <Box sx={{ minWidth: 180, maxWidth: 180}} marginRight={1}>
      <Box padding={1} paddingTop={0.5}>
        <Box display="flex" alignItems="center">
          <FallbackAvatar size={30} src={app.isAuthenticated?app.user.avatarUrl:undefined} />
          <TextInput sx={{marginLeft: 1}} variant="outlined" value={props.name.value} error={props.name.error} onChange={props.name.handleChange}  />
        </Box>
      </Box>

      <Box paddingLeft={2} marginTop={1} display="flex" flexDirection="column">
        <Typography variant="subtitle2" fontWeight="bold" >Wann passt es dir ? </Typography>
        <Typography variant="caption" color="testSecondary">Klicke auf die Knöpfe auf der rechten Seite.</Typography>
      </Box>

    </Box>

    <Box sx={{ flex: 1, width: 1, minHeight: "100%", height: "fit-content", overflowX: "overlay"}} className="scroll-invisible" id="schedule-options-foot">

      <ScheduleEntryRow schedule={props.schedule} values={props.values.value} />

      <Box marginTop={1} sx={{ marginBottom: "1px", minWidth: "100%", width: "fit-content"}} display="flex" alignItems="center">
        {props.schedule?.options.map((option, index)=> {
          const action = props.values.value.find((v : any) => v.scheduleOptionId === option.id)
          return <ScheduleEntryButtons key={index} action={action?.availability} onChange={props.handleValuesChange(option.id)} />
        })}
      </Box>

    </Box>
  </React.Fragment>

}