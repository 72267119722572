import React from 'react';
import {Box, Paper, Typography} from "@mui/material";
import EventNoteIcon from '@mui/icons-material/EventNote';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {getDay, getMonth} from "./ScheduleOptionsHead";

export type ScheduleCornerProps = {
  amount: number,
  bestOption?: any
}

export default function ScheduleCorner(props: ScheduleCornerProps) {

  let date = props.bestOption?.date ? new Date(Date.parse(props.bestOption?.date)) : undefined
  let dateString = date ? getDay(date.getFullYear(),date.getMonth(),date.getDate())+" "+date.getDate()+". "+getMonth(date.getMonth()) +" " + date.getHours().toString().padStart(2,'0')+":"+date.getMinutes().toString().padStart(2,'0') : "Loading.."

  return <Box sx={{height: "100%", minWidth: 180, maxWidth: 180}} marginRight={1}>
    <Paper sx={{minHeight: "100%"}} elevation={1}>
      <Box display="flex" padding={1} sx={{height: 80}} justifyContent="center" flexDirection="column">

        <Box display="flex" alignItems="center">
          <EventNoteIcon color="primary" />
          <Typography marginLeft={1} variant="caption" fontWeight="bold" color="textSecondary">{dateString}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <PeopleAltIcon color="primary" />
          <Typography marginLeft={1} variant="caption" color="textSecondary">{props.amount} {props.amount === 1 ? "Person" : "Personen"}</Typography>
        </Box>

      </Box>
    </Paper>
  </Box>
}