import React, {useEffect, useState} from 'react';
import AppBar from "./components/structural/AppBar";
import AppContent from "./components/structural/AppContent";
import {Navigate, Route, Routes} from 'react-router-dom';
import {AuthenticationService, LoginBehavior, useApplication} from "@aspor/aspor-react";
import {FluxusAppContainer} from "@fluxusui/fluxusui-base";
import ScheduleRoute from "./routes/schedules/ScheduleRoute";
import './style.css'
import LoadingScreen from "./components/structural/LoadingScreen";
import ScheduleListRoute from "./routes/schedules/ScheduleListRoute";
import AuthRequired from "./components/AuthRequired";

export type AppProps = {

}

export default function App(props : AppProps) {
  const app = useApplication()

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(()=>{
    app.service(AuthenticationService).login(LoginBehavior.SILENT)
        .finally(()=>setLoading(false))
  })

  if(loading){
    return <LoadingScreen />
  }else{
    return <FluxusAppContainer>
      <AppBar />
      <AppContent disableOverflow>

        <Routes>
          <Route path="/schedules/:scheduleId" element={<ScheduleRoute />}/>
          <Route path="/schedules" element={<AuthRequired element={()=><ScheduleListRoute />} />}/>
          <Route index element={<Navigate to="/schedules" replace />} />
        </Routes>

      </AppContent>

    </FluxusAppContainer>;
  }

}