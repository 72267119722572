import {AppBar as MuiAppBar, Box, Button, Container, IconButton, Toolbar, Typography} from "@mui/material";
import React from "react";
import {AsporComponent} from "@aspor/aspor-react";
import {RouteService} from "../../services/route/route.service";
import ProfilePopover from "../../popovers/ProfilePopover";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {FallbackAvatar, FlexBox } from "@fluxusui/fluxusui-base";

export type AppBarProps = {

}

export type AppBarState = {
    profileAnchorTarget: any
}

export default class AppBar extends AsporComponent<AppBarProps, AppBarState>{

    state : AppBarState = {
        profileAnchorTarget: null
    }

    handleProfileOpen = (event : any) => this.setState({profileAnchorTarget: event.target})
    handleProfileClose = () => this.setState({profileAnchorTarget: null})
    handleCreateSchedule = () => this.app.service(RouteService).push("/schedules?create=true")

    render(){
        return <div>
            <MuiAppBar >
                <Toolbar variant="dense" sx={{padding:0}}>

                    <Container maxWidth="lg" >
                        <FlexBox justifyContent="space-between">

                            <Box display="flex" alignItems="center" onClick={()=>this.app.service(RouteService).push("/")} style={{cursor: "pointer"}}>
                                <Typography variant="h6" fontWeight="bold">Eventrice</Typography>
                            </Box>

                            {this.app.isAuthenticated && (
                                <IconButton size="small" onClick={this.handleProfileOpen}>
                                    <FallbackAvatar src={this.app.user.avatarUrl} name={this.app.user.username} size={35}/>
                                </IconButton>
                            )}

                            {!this.app.isAuthenticated && (
                                <Button onClick={this.handleCreateSchedule} startIcon={<ExitToAppIcon />} sx={{color: "unset"}}>Kalender erstellen</Button>
                            )}

                        </FlexBox>
                    </Container>
                </Toolbar>
            </MuiAppBar>

            <ProfilePopover accountAnchorTarget={this.state.profileAnchorTarget} onClose={this.handleProfileClose} />
        </div>
    }

}
