

export class RouteService {

    private readonly router: (to: string,replace: boolean)=>void

    constructor(router: (to: string,replace: boolean)=>void) {
        this.router = router
    }

    push(to : string){
        this.router(to,false);
    }

    replace(to : string){
        this.router(to,true);
    }
}
