import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const ONLY_PREMIUM = "This feature is not included in your current plan, upgrade now to unlock this feature."

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        ns: "dashboard",
        fallbackLng: ['en', 'system'],
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            crossDomain: true,
            loadPath: process.env.NODE_ENV !== 'development' ? "https://locales.easyback.io/{{lng}}/{{ns}}.json" : "/locales/{{lng}}/{{ns}}.json"
        }
    });

export default i18n;