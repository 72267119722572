import {Application, AuthenticationService, ODataClient, ODataEntity, ODataService, ODataSet} from "@aspor/aspor-react";
import {Schedule, ScheduleEntry, ScheduleOption} from "../../entities";

export class DataService extends ODataService {

    constructor(app : Application) {
        super(new ODataClient(process.env.REACT_APP_API_SERVICE_ENDPOINT??"",app.service(AuthenticationService)));
    }

    schedules() : ODataSet<Schedule>
    schedules(id : string) : ScheduleODataEntity
    schedules(id? : string) : any {
        return id ? new ScheduleODataEntity(this,id,"schedules"): new ODataSet(this,"schedules");
    }

}

export class ScheduleODataEntity extends ODataEntity<Schedule> {

    entries() : EntryODataSet
    entries(id : string) : ODataEntity<ScheduleEntry>
    entries(id? : string) : any {
        return id ? new ODataEntity(this,id,"entries"): new EntryODataSet(this,"entries");
    }

    options() : ODataSet<ScheduleOption>
    options(id : string) : ODataEntity<ScheduleOption>
    options(id? : string) : any {
        return id ? new ODataEntity(this,id,"options"): new EntryODataSet(this,"options");
    }
}

export class EntryODataSet extends ODataSet<ScheduleEntry> {

    my() {
        return this.function<ScheduleEntry>("my")
    }

    myByUsername(username : string) {
        console.log(username)
        console.log(this.function<ScheduleEntry>("myByUsername", {username}))
        return this.function<ScheduleEntry>("myByUsername", {username})
    }
}