import React from 'react';
import {Typography} from "@mui/material";
import {FlexBox} from "@fluxusui/fluxusui-base";

export default function LoadingScreen() {
  return <FlexBox alignItems="center" justifyContent="center" width="100%"  height="calc(100vh - 60px)" flexDirection="column">

    <img src="https://content.eventrice.com/logo/logo.png" alt="Logo" width={100} height={100} />
    <Typography marginTop={2} color="primary" variant="h4" fontWeight="bold">Eventrice</Typography>
    <Typography marginTop={1} color="textSecondary">Loading..</Typography>

  </FlexBox>
}
