import React, {useMemo} from 'react';
import {Box, Paper, Typography} from "@mui/material";
import {OverflowTypography} from "@fluxusui/fluxusui-base";
import {Schedule} from "../../entities";

export type ScheduleHeadProps = {
  schedule?: Schedule
}

export default function ScheduleOptionsHead(props: ScheduleHeadProps) {

  const group = useMemo(()=>{

    let result : any[] = []
    let lastGroup : any = null;
    let lastDay: any = null

    if(props.schedule){
      for(let option of props.schedule.options){
        let date = new Date(Date.parse(option.date))
        if(lastGroup === null || lastGroup.year !== date.getFullYear() || lastGroup.month !== date.getMonth()){
          lastDay = {day: date.getDate(), times: [{hours: date.getHours(), minutes: date.getMinutes()}]}
          lastGroup = {
            year: date.getFullYear(),
            month: date.getMonth(),
            days: [lastDay]
          }
          result.push(lastGroup)
        }else{
          if(lastDay.day === date.getDate()){
            lastDay.times.push({hours: date.getHours(), minutes: date.getMinutes()})
          }else{
            lastDay = {day: date.getDate(), times: [{hours: date.getHours(), minutes: date.getMinutes()}]}
            lastGroup.days.push(lastDay)
          }
        }
      }
    }

    return result
    // eslint-disable-next-line
  },[props.schedule?.options])

  return <Box sx={{ flex: 1, width: 1, minHeight: "100%", height: "fit-content", overflowX: "overlay"}} id="schedule-options-head" className="fluxus-overlay-scroll">
    <Paper elevation={1} sx={{borderBottom: (theme)=>"1px solid "+theme.palette.primary.main, height: 88,paddingTop: "2px", paddingBottom: "2px", display: "flex", width: "fit-content", minWidth: "100%"}}>
      {group.map((group)=>
          <Box key={group.month} sx={{height: "100%", borderRight: "1px solid rgba(0, 0, 0, 0.12)"}} display="flex" flexDirection="column">
            <Box height={40} display="flex" alignItems="center" justifyContent="center">
              <Typography variant="caption">{getMonth(group.month)}</Typography>
            </Box>
            <Box display="flex" sx={{flex: 1}}>
              {group.days.map((day : any, index: number)=>
                  <Box key={index} sx={{flex: 1, borderRight: index!==group.days.length-1?"1px solid rgba(0, 0, 0, 0.12)":undefined}} display="flex" flexDirection="column">
                    <Box sx={{flex: 1}} display="flex" alignItems="center" justifyContent="center">
                      <OverflowTypography variant="caption">{getDay(group.year,group.month, day.day)} {day.day}.</OverflowTypography>
                    </Box>
                    <Box display="flex">
                      {day.times.map((time : any, index2: number)=>(
                          <Box key={index2} sx={{borderRight: index2!==day.times.length-1?"1px solid rgba(0, 0, 0, 0.12)":undefined}}>
                            <Box sx={{ width: 39, height: 20, marginRight: "1px"}} display="flex" alignItems="center" justifyContent="center">
                              <Typography variant="caption">{time.hours.toString().padStart(2,'0')+":"+time.minutes.toString().padStart(2,'0')}</Typography>
                            </Box>
                          </Box>
                      ))}
                    </Box>
                  </Box>
              )}
            </Box>
          </Box>
      )}
    </Paper>
  </Box>
}

export function getDay(year: number, month: number, day: number){
  let weekDay = new Date(year, month, day-1,0,0,0).getDay()
  if(weekDay === 0) return "Mo";
  else if(weekDay === 1) return "Di";
  else if(weekDay === 2) return "Mi";
  else if(weekDay === 3) return "Do";
  else if(weekDay === 4) return "Fr";
  else if(weekDay === 5) return "Sa";
  else if(weekDay === 6) return "So";
  return "?"
}


export function getMonth(month: number){
  if(month === 0) return "Jan";
  else if(month === 1) return "Feb";
  else if(month === 2) return "Mär";
  else if(month === 3) return "Apr";
  else if(month === 4) return "Mai";
  else if(month === 5) return "Jun";
  else if(month === 6) return "Jul";
  else if(month === 7) return "Aug";
  else if(month === 8) return "Sep";
  else if(month === 9) return "Okt";
  else if(month === 10) return "Nov";
  else if(month === 11) return "Dez";
  return "?"
}