import {AuthenticationService, LoginBehavior, useApplication} from "@aspor/aspor-react";
import React from "react";

export type AuthRequiredProps = {
    element: ()=>any
}

export default function AuthRequired(props: AuthRequiredProps){
    const app = useApplication()

    if(!app.isAuthenticated){
        app.service(AuthenticationService).login(LoginBehavior.INTERACTIVE);
        return <React.Fragment />
    }else{
        return props.element();
    }
}