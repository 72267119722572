import React, {useEffect, useState} from "react";
import {Box, Button, Container, Divider, IconButton, Paper, Typography} from "@mui/material";
import {
    useServiceODataCollectionResponse,
} from "@aspor/aspor-react";
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from "react-router-dom";
import {DataService} from "../../services/data/data.service";
import LaunchIcon from '@mui/icons-material/Launch';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {GlobalWindowTitleConfig, OverflowTypography, Space, useDialog} from "@fluxusui/fluxusui-base";
import ScheduleModifyDialog from "../../dialogs/ScheduleModifyDialog";
import {Schedule} from "../../entities";
import ScheduleDeleteDialog from "../../dialogs/ScheduleDeleteDialog";

export type ScheduleListRouteProps = {

}

export default function ScheduleListRoute(props: ScheduleListRouteProps){
    const navigate = useNavigate()

    const modifyDialog = useDialog()
    const deleteDialog = useDialog()

    const [selectedSchedule, setSelectedSchedule] = useState<Schedule>();

    const [schedules,,,,reloadSchedules] = useServiceODataCollectionResponse(DataService
        ,(service)=>service.schedules()
            .orderBy(o => o.createdOn)
            .expandMany(o => o.options)
            .top(25).getMany()
        ,[])

    const handleShare = (scheduleId : string) => (e : any) => {
        e.stopPropagation();
        navigator.share({title: document.title.replace(GlobalWindowTitleConfig.SUFFIX,""), url: window.location.href+"/"+scheduleId})
    }

    const handleCreate = () => {
        modifyDialog.open();
        setSelectedSchedule(undefined)
    }

    const handleModify = (schedule: Schedule) => (e : any) => {
        e.stopPropagation();
        setSelectedSchedule(schedule)
        modifyDialog.open();
    }

    const handleDelete = (schedule: Schedule) => (e : any) => {
        e.stopPropagation();
        setSelectedSchedule(schedule)
        deleteDialog.open();
    }

    useEffect(()=>{
       let params = new URLSearchParams(window.location.search)
       if(params.has("create")) modifyDialog.open();
        // eslint-disable-next-line
    },[])

    return <Container maxWidth="lg" >

        <Box paddingTop={2} paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <Box sx={{borderRadius: 12, background: (theme)=>theme.palette.action.selected}} padding={1} display="flex" alignItems="center" justifyContent="center">
                    <EventNoteIcon color="primary" />
                </Box>
                <Typography marginLeft={2} variant="h6" fontWeight="bold">Meine Kalender</Typography>
            </Box>
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleCreate}>Neuer Kalender</Button>
        </Box>
        <Divider />

        <Box paddingTop={2}>
            {schedules.map((schedule)=>
                <React.Fragment key={schedule.id} >
                    <Paper onClick={()=>navigate("/schedules/"+schedule.id)} sx={{cursor: "pointer"}}>
                        <Box padding={1} display="flex" alignItems="center" justifyContent="space-between">
                            <OverflowTypography variant="subtitle1" fontWeight="bold">{schedule.name}</OverflowTypography>
                            <Box>
                                <IconButton size="small" onClick={handleDelete(schedule)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                <IconButton size="small" onClick={handleModify(schedule)}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton size="small" onClick={handleShare(schedule.id)}>
                                    <ShareIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                    </Paper>
                    <Space spacing={1}/>
                </React.Fragment>
            )}
        </Box>

        <ScheduleModifyDialog state={modifyDialog} schedule={selectedSchedule} onReload={()=>reloadSchedules()} />
        <ScheduleDeleteDialog state={deleteDialog} schedule={selectedSchedule} onReload={()=>reloadSchedules()} />

    </Container>

}