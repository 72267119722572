import axios from "axios";
import {Application} from "@aspor/aspor-react";
import {GeneralUtil} from "@fluxusui/fluxusui-base";
import { DataService } from "../data/data.service";
import { ControllerInsideStepProps } from "../../components/scheduler/controller/ControllerInside";
import { ScheduleEntry } from "../../entities";

export class UserService {

    private readonly _tenant : string = "a3cca9ba-e96c-4774-9fd9-5d514f06134a";
    private readonly _cachedUsers: any
    private readonly _loadingUsers: any
    private readonly _app: any

    constructor(app : Application) {
        this._cachedUsers = {}
        this._loadingUsers = {}
        this._app = app
    }

    getAvatar(id? : string, tenantId0?: string) {
        let tenantId = tenantId0??this._tenant;
        if(id) return "https://lookup.cloud2a.com/v1/"+tenantId+"/users/"+id+"/avatar"
        else return ""
    }

    getAnonymousToken(username: string, props: ControllerInsideStepProps){
        let token = window.localStorage.getItem("anonymous.token")
        if(!token) {
            //token = this._app.service(DataService).schedules(props.schedule!.id).entries().get().filter((entry: ScheduleEntry) => entry.userName === username).token;
            /*token = this._app.service(DataService).schedules(props.schedule!.id).entries().get()
                .then((results: any[]) => results.find((entry: { userName: string; }) => entry.userName === username))
                .then((filteredResults: { body: () => { (): any; new(): any; token: any; }; }) => filteredResults.body().token);*/
            if(!token){
                token = GeneralUtil.generateRandomUUID()+"-"+GeneralUtil.generateRandomUUID();
                window.localStorage.setItem("anonymous.token", token)
            }
        }
        return token;
    }

    getAnonymousName(){
        return window.localStorage.getItem("anonymous.name")
        //Test
    }

    setAnonymousName(name: string){
        window.localStorage.setItem("anonymous.name", name)
    }

    getUser(id : string, tenantId0?: string) : Promise<User> {
        let tenantId = tenantId0??this._tenant;
        let cachedUser = this._cachedUsers[id];
        if(cachedUser){
            return Promise.resolve(cachedUser)
        }else if(this._loadingUsers[id]){
            return new Promise<User>((resolve,reject)=>{
                this._loadingUsers[id].push({resolve,reject});
            })
        }else{
            this._loadingUsers[id] = []
            return new Promise<User>((resolve)=>{
                axios.get("https://lookup.cloud2a.com/v1/"+tenantId+"/users/"+id).then((response)=>{
                    let user = response.data as User;
                    this._cachedUsers[id] = user;
                    for(let promise of this._loadingUsers[id]){
                        promise.resolve(user);
                    }
                    this._loadingUsers[id] = undefined;
                    resolve(user);
                })
            })
        }
    }

}

export interface User {

    id: string

    username: string

    discriminator: string

}
