import React from "react";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@mui/material";
import {Schedule} from "../entities";
import {
    IconDialogTitle,
    ControlledDialog,
    ActionButton,
} from "@fluxusui/fluxusui-base";
import {DialogState} from "@fluxusui/fluxusui-base/hooks/useDialogHook";
import {NotificationService} from "../services/notification/notification.service";
import {useApplication} from "@aspor/aspor-react";
import {DataService} from "../services/data/data.service";
import DeleteIcon from '@mui/icons-material/Delete';

export type ConfirmDeleteDialogProps = {
    state: DialogState,
    schedule?: Schedule,
    onReload: ()=>void
}

export default function ScheduleDeleteDialog(props : ConfirmDeleteDialogProps){
    const app = useApplication()

    const handleSubmit = () => {
        return app.service(DataService).schedules(props.schedule!.id!).delete().now()
    }

    const handleSubmitComplete = () => {
        props.state.close();
        props.onReload();
    }

    const handleSubmitFailure = (error : any) => {
        if(error.message !== "Validation error") app.service(NotificationService).handleError(error);
    }

    return <ControlledDialog state={props.state} >
        <IconDialogTitle title={"Kalender löschen"} icon={DeleteIcon}/>

        <DialogContent>

            <DialogContentText>
                Bist du sicher diesen Kalender zu löschen?
            </DialogContentText>

        </DialogContent>

        <DialogActions>
            <Button onClick={props.state.close}>Abbrechen</Button>
            <ActionButton variant="contained"
                          onClick={handleSubmit}
                          onComplete={handleSubmitComplete}
                          onFailure={handleSubmitFailure} >{"Löschen"}</ActionButton>
        </DialogActions>
        
    </ControlledDialog>
}

