import React, {useEffect, useMemo} from 'react';
import {Box, Divider, Paper, Typography} from "@mui/material";
import {Space} from "@fluxusui/fluxusui-base";
import ScheduleEntryInfo from "./entry/ScheduleEntryInfo";
import ScheduleOptionsHead from "./ScheduleOptionsHead";
import ScheduleEntryRow from "./entry/ScheduleEntryRow";
import {Schedule} from "../../entities";
import ScheduleController from "./controller/ScheduleController";
import ScheduleCorner from "./ScheduleCorner";
import {useServiceODataCollectionResponse} from "@aspor/aspor-react";
import {DataService} from "../../services/data/data.service";

export type SchedulerProps = {
  schedule?: Schedule
}

export default function ScheduleContainer(props: SchedulerProps) {

  const [entries,,,,reloadEntries] = useServiceODataCollectionResponse(DataService
      ,(service)=>props.schedule?.id ? service.schedules(props.schedule.id).entries()
          .expandMany(s => s.values)
          .top(1000).getMany() : undefined
      ,[props.schedule?.id])

  useEffect(()=>{
    if(props.schedule?.id){
      //@Todo optimize with delta
      let interval = setInterval(reloadEntries,3000)
      return ()=>clearInterval(interval)
    }
  },[props.schedule?.id])

  useEffect(()=>{
    const head = document.getElementById("schedule-options-head")!;
    const body = document.getElementById("schedule-options-body")!;
    const foot = document.getElementById("schedule-options-foot");

    const listener1 = (e : any)=>{
      body.scrollLeft = e.currentTarget.scrollLeft
      if(foot) foot.scrollLeft = e.currentTarget.scrollLeft
    }
    head.addEventListener("scroll",listener1)

    const listener2 = (e : any)=>{
      head.scrollLeft = e.currentTarget.scrollLeft
      if(foot) foot.scrollLeft = e.currentTarget.scrollLeft
    }
    body.addEventListener("scroll", listener2)

    const listener3 = (e : any)=>{
      body.scrollLeft = e.currentTarget.scrollLeft
      head.scrollLeft = e.currentTarget.scrollLeft
    }
     if(foot){
       foot.addEventListener("scroll",listener3)
     }

     return ()=>{
       head.removeEventListener("scroll",listener1)
       body.removeEventListener("scroll",listener2)
       if(foot) foot.removeEventListener("scroll",listener3)
     }

  })

  const bestOption = useMemo(()=>{
    let calculation =  entries.flatMap(e => e.values)
        .reduce((rv : any, value) => {
          (rv||{})[value.scheduleOptionId] = ((rv||{})[value.scheduleOptionId]??0)+(value.availability === 0 ? 1 : value.availability === 1 ? 0.25 : 0);
          return rv;
        }, {})
    let bestId : any = null;
    let bestAmount;
    for(let key of Object.keys(calculation)){
      if(!bestAmount || bestAmount < calculation[key]){
        bestId = key;
        bestAmount =calculation[key];
      }
    }
    return props.schedule?.options.find((o)=>o.id === bestId);
  },[entries]);

  return <Box display="flex" sx={{height: "100%"}} flexDirection="column">

    <Box display="flex" >
      <ScheduleCorner amount={entries.reduce((partialSum, entry) => partialSum + entry.amount, 0)} bestOption={bestOption} />
      <ScheduleOptionsHead schedule={props.schedule} />
    </Box>

    <Box sx={{flex: 1, height: 0, overflow: "overlay"}} className="fluxus-overlay-scroll" display="flex" marginTop={1}>
      <Box sx={{height: "100%", minWidth: 180, maxWidth: 180}} marginRight={1}>
        <Paper sx={{minHeight: "calc(100% - 8px)"}}>
          <Box padding={1} >
            <Divider />
            {entries.map((entry)=> <ScheduleEntryInfo key={entry.id} entry={entry} />)}
            {entries && entries.length === 0 && (<Typography sx={{marginTop: 0.5}} marginLeft={1} variant="subtitle2" color="textSecondary">Noch keine Einträge</Typography>)}
          </Box>
        </Paper>
        <Space spacing={1}/>
      </Box>

      <Box sx={{ flex: 1, width: 1, minHeight: "100%", height: "fit-content", overflowX: "overlay"}} className="scroll-invisible" id="schedule-options-body">
        <Box sx={{height: "8px"}}/>
        {entries.map((entry)=> <ScheduleEntryRow key={entry.id} schedule={props.schedule} values={entry.values} />)}
      </Box>
    </Box>

    <ScheduleController schedule={props.schedule} />
  </Box>

}