import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import {FallbackAvatar, TextInput} from "@fluxusui/fluxusui-base";
import {Schedule} from "../../../../entities";
import ScheduleEntryRow from "../../entry/ScheduleEntryRow";
import {useApplication} from "@aspor/aspor-react";
import {FormProperty} from "@fluxusui/fluxusui-base/libraries/form/hooks/useFormProperty.hook";

export type ControllerCommentStepProps = {
  schedule?: Schedule,
  name: FormProperty<string>
  values: FormProperty<any>
  comment: FormProperty<string>
  onNext: ()=>void
  controls: boolean
}

export default function ControllerCommentStep(props: ControllerCommentStepProps) {
  const app = useApplication()

  return <React.Fragment>
    <Box sx={{ minWidth: 180, maxWidth: 180}} marginRight={1}>
      <Box padding={1} paddingTop={0.5}>
        <Box display="flex" alignItems="center">
          <FallbackAvatar size={30} src={app.isAuthenticated?app.user.avatarUrl:undefined} />
          <TextInput sx={{marginLeft: 1}} variant="outlined" value={props.name.value} error={props.name.error} onChange={props.name.handleChange}  />
        </Box>
      </Box>

      <Box paddingLeft={2} marginTop={1} display="flex" flexDirection="column">
        <Typography variant="subtitle2" fontWeight="bold" >Möchtest du noch eine Nachricht hinterlassen? </Typography>
        {props.controls && (
            <Box marginTop={1}>
              {props.comment.value.length === 0 && (<Button variant="outlined" size="small" onClick={props.onNext}>Nein</Button>)}
              {props.comment.value.length > 0 && (<Button variant="outlined" size="small" onClick={props.onNext}>Ja</Button>)}
            </Box>
        )}
      </Box>

    </Box>

    <Box sx={{flex: 1, minHeight: "100%", height: "fit-content", overflowX: "overlay"}} className="scroll-invisible" id="schedule-options-foot" display="flex" flexDirection="column">

      <ScheduleEntryRow schedule={props.schedule} values={props.values.value} />

      <Box marginTop={1} sx={{marginBottom: "1px", width: "100%", maxWidth: "100%", flex: 1}} paddingRight={2}>

        <TextInput multiline rows={3} variant="outlined" placeholder="Deine Nachricht" value={props.comment.value} onChange={props.comment.handleChange} />

      </Box>

    </Box>
  </React.Fragment>

}