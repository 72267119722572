import React from 'react';
import {Box, Divider, Typography} from "@mui/material";
import {FallbackAvatar, OverflowTypography, usePopover} from "@fluxusui/fluxusui-base";
import CommentIcon from '@mui/icons-material/Comment';
import {ScheduleEntry} from "../../../entities";
import {useService} from "@aspor/aspor-react";
import {UserService} from "../../../services/user/user.service";
import InfoPopover from "../../../popovers/InfoPopover";

export type ScheduleEntryProps = {
    entry: ScheduleEntry,
}

export default function ScheduleEntryInfo(props: ScheduleEntryProps) {
    const userService = useService(UserService)

    const popover = usePopover()

    const anonymous = props.entry.userId === null;
    const amount = props.entry.amount;

    return <React.Fragment>
        <Box display="flex" alignItems="center" height={40} sx={{cursor: "pointer"}} justifyContent="space-between" onClick={popover.open}>
            <Box display="flex" alignItems="center">
                {anonymous && (<FallbackAvatar size={30} name={props.entry.userName} src={props.entry.userPictureUrl} />)}
                {!anonymous && (<FallbackAvatar size={30} src={userService.getAvatar(props.entry.userId)} />)}
                <OverflowTypography marginLeft={1} variant="subtitle1" fontWeight="bold">{props.entry.userName}</OverflowTypography>
                {amount > 1 && (<Typography marginLeft={0.5} variant="body2" sx={{color: "#0288d1"}} >({amount})</Typography>)}
            </Box>
            {props.entry.comment && (<CommentIcon fontSize="small" sx={{fontSize: 15, color: (theme)=>theme.palette.text.secondary}} />)}
        </Box>
        <Divider />

        <InfoPopover state={popover} entry={props.entry} />

    </React.Fragment>

}
