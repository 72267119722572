import {Application, AsporUser, IAuthenticationService, LoginBehavior} from "@aspor/aspor-react";
import {NotificationService} from "../notification/notification.service";
import {LocalBrowserStorage, PublicClientApplication} from "@cloud2a/cloud2a-client";
import {EndpointConfiguration} from "@cloud2a/cloud2a-client/types";

export default class PretronicAuthenticationService implements IAuthenticationService {

    _app : Application;
    _client: PublicClientApplication;
    _configuration?: EndpointConfiguration;
    _user?: AsporUser;

    _acquiring: boolean

    constructor(app : Application) {
        this._app = app;

        this._client = new PublicClientApplication({
            endpoint: "https://auth.cloud2a.com/4b403e48-9022-49ac-abdc-d24dcd8617d1/v2/a3cca9ba-e96c-4774-9fd9-5d514f06134a",
            clientId: "ad4ad77a-bd15-4073-9357-66b009170107",
            defaultScope: "user",
            storage: new LocalBrowserStorage()
        })

        this._client.getConfiguration().then((configuration)=>{
            this._configuration = configuration;
        })

        this._acquiring = false;
    }

    getSchema(): string {
        return "Bearer";
    }

    getAuthorizationHeader(): any {
        return "Bearer "+this._client.getLatestToken().accessToken;
    }

    acquireAuthorizationHeader(): Promise<string> {
        return new Promise<string>((resolve, reject)=>{
            return this._client.acquireTokenSilent()
                .then((token)=>resolve("Bearer "+token.accessToken))
                .catch(reject)
        })
    }

    getAuthorizationToken(): any {
        return this._client.getLatestToken().accessToken;
    }

    acquireAuthorizationToken(): Promise<string> {
        return new Promise<string>((resolve, reject)=>{
            return this._client.acquireTokenSilent()
                .then((token)=>resolve(token.accessToken))
                .catch(reject)
        })
    }

    isAuthenticated() : boolean{
        return Boolean(this._user)
    }

    getUser(): AsporUser {
        // @ts-ignore => temporary please optimize
        return this._user;
    }

    login(behavior?: LoginBehavior): Promise<AsporUser> {
        if(behavior === LoginBehavior.INTERACTIVE) return this.authenticate(false);
        else if(behavior === LoginBehavior.INTERACTIVE_ASK) return this.authenticate(true);
        else return this.authenticateSilent();
    }

    async authenticate(forceDialog?: boolean) : Promise<AsporUser> {
        return new Promise<any>((resolve)=>{
            this._client.acquireTokenSilent()
                .then((token)=>{
                    this._user = this.convertUser(token.decodedToken);
                    resolve(this._user);
                }).catch(()=>this.handleLogin(forceDialog))
        })
    }

    async authenticateSilent() : Promise<AsporUser>{
        return new Promise<any>((resolve)=>{
            this._client.acquireTokenSilent()
                .then((token)=>{
                    this._user = this.convertUser(token.decodedToken);
                    resolve(this._user);
                }).catch((error)=>{
                    resolve(null)
            })
        })
    }

    handleLogin(forceDialog?: boolean){
        if(!this._configuration){
            setTimeout(()=>{
                this.handleLogin(forceDialog)
            },50)
        }
        if(this._configuration?.authentication.preferred === "select_option") {
            this.redirectToProvider()
        }else if(this._configuration?.authentication.preferred === "select_option_popup" || this._configuration?.authentication.preferred == null) {
            if(this._configuration?.authentication.methods.length === 0){
                this._app.service(NotificationService).sendError("No authentication provider available");
            }else if(this._configuration?.authentication.methods.length === 1){
                this.redirectToProvider(this._configuration.authentication.methods[0].name)
            }
        }else {
            this.redirectToProvider(this._configuration?.authentication.preferred)
        }
    }

    redirectToProvider = (method?: string) => {
        this._client.acquireTokenInteractive(method)
    }

    logout(){
        this._client.logout("session");
        this._user = undefined;
    }

    private convertUser = (decodedToken : any) => {
        return {
            id: decodedToken.sub,
            username: decodedToken.preferred_username,
            discriminator: decodedToken.discriminator,
            email: decodedToken.email,
            avatarUrl: decodedToken.picture
        }
    }
}