import React from 'react';
import {Box, IconButton, useTheme} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export type ScheduleEntryProps = {
    action?: number,
    onChange: (action: number)=>void
}

export default function ScheduleEntryButtons(props: ScheduleEntryProps) {
    const theme = useTheme()
    const selectedColor = theme.palette.action.selected;
    return <Box sx={{width: 41}}>
        <Box sx={{height: 40, width: 40, marginRight: "1px"}}>
            <IconButton style={{background: props.action === 0 ? selectedColor : undefined}} onClick={()=>props.onChange(0)}>
                <TaskAltIcon sx={{color: "#66bb6a"}} />
            </IconButton>
        </Box>

        <Box sx={{height: 40, width: 40, marginRight: "1px"}}>
            <IconButton style={{background: props.action === 2 ? selectedColor : undefined}} onClick={()=>props.onChange(2)}>
                <HighlightOffIcon sx={{color: "#f44336"}} />
            </IconButton>
        </Box>

        <Box sx={{height: 40, width: 40, marginRight: "1px"}}>
            <IconButton style={{background: props.action === 1 ? selectedColor : undefined}} onClick={()=>props.onChange(1)}>
                <HelpOutlineIcon sx={{color: "#ffa726"}}  />
            </IconButton>
        </Box>
    </Box>

}
