import {useApplication} from "@aspor/aspor-react";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {RouteService} from "./route.service";

export type RouteServiceProviderProps = {
  children?: any
}

export function RouteServiceInjector(props: RouteServiceProviderProps) {
  let app = useApplication();
  let navigate = useNavigate()

  useEffect(()=>{
    app.registerService(new RouteService((to, replace)=>{
      navigate(to,{replace: replace})
    }))
    return ()=>{
      app.unregisterService(RouteService)
    }
  })

  return props.children;
}
