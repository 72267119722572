
export interface DialogRequest {
    title?: any,
    icon?: any,
    content?: any,
    onConfirm?: ()=>void,
    onCancel?: ()=>void
}

export class DialogService {

    private readonly openDialog: (dialogRequest: DialogRequest)=>void

    constructor(openDialog: (dialogRequest: DialogRequest)=>void) {
        this.openDialog = openDialog;
    }

    showInfo(content: any, title?: any, icon?: any){
        this.openDialog({title, icon, content})
    }

    askConfirm(content?: string,title?: any, icon?: any) : Promise<void>{
        return new Promise<void>((resolve,reject)=>{
            this.openDialog({title, icon, content, onConfirm: resolve, onCancel: reject})
        })
    }
}
