import {InputValidator, ValidationResult} from "@fluxusui/fluxusui-base";

export const RequiresOptions = () : InputValidator => {
    return {
        validate: function (input: any): ValidationResult {
            if(input?.length < 2 || input[0] === null || input[1] === null){
                return {valid: false, message: "Mindestens 2 Optionen werden benötigt"}
            }
            return {valid: true}
        }
    }
}

export const ValidDates = () : InputValidator => {
    return {
        validate: function (input: any): ValidationResult {
            if(input){
                for(let date of input){
                    try{
                        if(date?.date){
                            if(typeof date?.date === "string") new Date(Date.parse(date.date)).toISOString()
                            else date.date.toISOString()
                        }
                    }catch (e){
                        return {valid: false, message: "Invalid date"}
                    }
                }
            }
            return {valid: true}
        }
    }
}