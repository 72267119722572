import React, {useState} from 'react';
import {Box, Button, Divider, Typography} from "@mui/material";
import {Space, TextInput} from "@fluxusui/fluxusui-base";
import {Schedule} from "../../../entities";
import {AuthenticationService, useApplication, useService} from "@aspor/aspor-react";
import {UserService} from "../../../services/user/user.service";
import PretronicAuthenticationService from "../../../services/authentication/pretronic.authorization.service";

export type ControllerLoginStepProps = {
  schedule?: Schedule,
  onContinue: ()=>void
}

export default function ControllerLogin(props: ControllerLoginStepProps) {
  const app = useApplication()
  const userService = useService(UserService)

  const [name, setName] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  const handleContinue = () => {
    if(name.length < 3) setError(true)
    else {
      userService.setAnonymousName(name)
      props.onContinue()
    }
  }

  const handleLogin = (method: string) => () => {
    const provider = app.service(AuthenticationService) as PretronicAuthenticationService
    provider.redirectToProvider(method)
  }

  const onKeyDown = (e : any) => {
    if(e.key === 'Enter') handleContinue()
  }

  return <Box padding={2}>

    <Typography variant="h5" fontWeight="bold" textAlign="center">Hi, gib deinen Namen ein</Typography>

    <Box display="flex" alignItems="center" paddingTop={1} paddingBottom={1}>
      <Box sx={{flex: 1}} >
        <TextInput variant="outlined" placeholder="Sara" value={name} onChange={(e)=>setName(e.target.value)} error={error?"Name is required":undefined} onKeyDown={onKeyDown} />
      </Box>
      <Box marginLeft={1} marginTop={"-4px"}>
        <Button variant="contained" onClick={handleContinue} >Continue</Button>
      </Box>

    </Box>

    <Box display="flex" alignItems="center">
      <Divider sx={{flex:1 }} />
      <Box paddingRight={2} paddingLeft={2}>
        <Typography variant="caption" fontWeight="bold" color="textSecondary">ODER</Typography>
      </Box>
      <Divider sx={{flex:1 }} />
    </Box>

    <Box display="flex" alignItems="center" justifyContent="center" marginTop={1} onClick={handleLogin("google")}
         sx={{cursor: "pointer", height: 30, borderRadius: 1, background: (theme)=> theme.palette.action.selected}}>
      <img src={"https://content.easyback.io/pretronic-account/providers/google.png"} alt="Icon" width={20} height={20} />
      <Typography variant="subtitle2" marginLeft={2} color="textSecondary">Mit Google einloggen</Typography>
    </Box>

    <Space spacing={1}/>

    <Box display="flex" alignItems="center" justifyContent="center" marginTop={1} onClick={handleLogin("microsoft")}
         sx={{cursor: "pointer", height: 30, borderRadius: 1, background: (theme)=> theme.palette.action.selected}}>
      <img src={"https://content.easyback.io/pretronic-account/providers/microsoft.png"} alt="Icon" width={20} height={20} />
      <Typography variant="subtitle2" marginLeft={2} color="textSecondary">Mit Microsoft einloggen</Typography>
    </Box>

  </Box>

}
