import React from 'react';
import {AsporComponent} from "@aspor/aspor-react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {DialogRequest, DialogService} from "./dialog.service";
import ReportIcon from '@mui/icons-material/Report';
import InfoIcon from '@mui/icons-material/Info';
import { IconDialogTitle } from '@fluxusui/fluxusui-base';

export type DialogServiceProviderProps = {

}

export type DialogServiceProviderState = {
  request?: DialogRequest
}

export default class DialogServiceProvider extends AsporComponent<DialogServiceProviderProps, DialogServiceProviderState> {

  state : DialogServiceProviderState = {
    request: undefined
  }

  componentDidMount() {
    this.app.registerService(new DialogService((request)=>{
      this.setState({request})
    }))
  }

  handleConfirm = () => {
    if(this.state.request?.onConfirm) this.state.request?.onConfirm();
    this.setState({request: undefined})
  }

  handleCancel = () => {
    if(this.state.request?.onCancel) this.state.request?.onCancel();
    this.setState({request: undefined})
  }

  render() {
    return <Dialog open={this.state.request !== undefined} onClose={this.handleCancel} fullWidth maxWidth="sm">
      <IconDialogTitle title={this.getTitle()} icon={this.getIcon()} />
      <DialogContent>

        <DialogContentText>
          {this.state.request?.content}
        </DialogContentText>

      </DialogContent>

      <DialogActions>
        <Button onClick={this.handleCancel} >Cancel</Button>
        <Button variant="contained" onClick={this.handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  }

  getTitle(){
    if (this.state.request?.title) return this.state.request?.title
    else if (this.state.request?.onConfirm) return "Danger - please confirm this action"
    else return "Information"
  }

  getIcon() {
    if (this.state.request?.icon) return this.state.request?.icon
    else if (this.state.request?.onConfirm) return ReportIcon
    else return InfoIcon
  }
}
