import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, IconButton, MobileStepper, Typography} from "@mui/material";
import {FormHelper, MinLength, useForm, useFormProperty} from "@fluxusui/fluxusui-base";
import {Schedule, ScheduleEntry} from "../../../entities";
import {ODataCollectionResponse, useApplication} from "@aspor/aspor-react";
import {UserService} from "../../../services/user/user.service";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import ControllerAvailabilityStep from "./steps/ControllerAvailabilityStep";
import ControllerMorePeopleStep from "./steps/ControllerMorePeopleStep";
import ControllerCommentStep from "./steps/ControllerCommentStep";
import {DataService} from "../../../services/data/data.service";
import {NotificationService} from "../../../services/notification/notification.service";
import CheckIcon from '@mui/icons-material/Check';

export type ControllerInsideStepProps = {
  schedule?: Schedule,
  onComplete: ()=>void
}

let SAVING = false;

export default function ControllerInside(props: ControllerInsideStepProps) {
  const app = useApplication()

  const [step, setStep] = useState<number>(0)
  const [finished, setFinished] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const [peopleControls, setPeopleControls] = useState<boolean>(true)
  const [commentControls, setCommentControls] = useState<boolean>(true)

  const [id, setId] = useState<string>()
  const [form, completeForm, setOriginal] = useForm<any>({
    userName: app.isAuthenticated ? app.user.username : app.service(UserService).getAnonymousName()??"",
    values: [],
    amount: 0,
    comment: ""
  },undefined,[])

  const name = useFormProperty<string>(form,"userName", [MinLength(3)])
  const values = useFormProperty<any[]>(form,"values")
  const amount = useFormProperty<number>(form,"amount")
  const comment = useFormProperty<string>(form,"comment")

  const handleOptionsChange = (optionId: string) => (newAction: number) => {
    let newValues = [...values.value]
    let action = values.value.find(v => v.scheduleOptionId === optionId)
    if(action) action.availability = newAction;
    else newValues.push({ scheduleOptionId: optionId, availability: newAction })
    values.setValue(newValues)

    if(!finished && newValues !== values.value && newValues.length === props.schedule?.options.length){
      setStep(1)
      setFinished(true)
    }
  }

  const handlePeopleNext = () => {
    setStep(2)
    setPeopleControls(false)
  }

  const handleCommentsNext = () => {
    setStep(0)
    setCommentControls(false)
    props.onComplete();
  }

  const handleSave = () => {
    if(SAVING) return;
    SAVING=true;
    let request;
    if(id) request = app.service(DataService).schedules(props.schedule!.id).entries(id).put(form.getObject())
    else request = app.service(DataService).schedules(props.schedule!.id).entries().post(form.getObject())

    if(!app.isAuthenticated) request = request.withHeader("ClientToken", app.service(UserService).getAnonymousToken(name.value, props));

    request.now().then((result : any)=>{
      if(!id) {
        setId(result.id)
        window.localStorage.setItem("schedule."+props.schedule!.id,result.id)
      }
      completeForm()
    })
        .catch(app.service(NotificationService).handleError)
        .finally(()=>SAVING=false)
  }

  const extractOriginal = (result: any) => {
    setId(result.id)
    setOriginal({
      userName: result.userName,
      amount: result.amount,
      comment: result.comment,
      values: result.values.map((value : any)=>({
        scheduleOptionId: value.scheduleOptionId,
        availability: value.availability
      }))
    })
    setPeopleControls(false)
    setFinished(true)
    setCommentControls(false)
    props.onComplete();
  }

  useEffect(()=>{
    if(props.schedule){
      if(app.isAuthenticated){
        app.service(DataService).schedules(props.schedule!.id).entries().my().expandMany(e => e.values).get().now()
            .then(extractOriginal)
            .finally(()=>setLoading(false))
      }else if(window.localStorage.getItem("schedule."+props.schedule!.id)){
        let entryId = window.localStorage.getItem("schedule."+props.schedule!.id)
        if(entryId){
          app.service(DataService).schedules(props.schedule!.id).entries(entryId).expandMany(e => e.values)
              .get().now()
              .then(extractOriginal)
              .finally(()=>setLoading(false))
        }else{
          setLoading(false)
        }
      }else{
        app.service(DataService).schedules(props.schedule!.id).entries().myByUsername(name.value).expandMany(e => e.values).get().now()
          .then(extractOriginal)
          .finally(()=>setLoading(false))
      }
    }
  },[props.schedule])

  useEffect(()=>{
    if(form.hasChanged() && values.value.length > 0){
      let timeout = setTimeout(handleSave,800)
      return ()=> clearTimeout(timeout)
    }
  },[values.value, name.value, comment.value, amount.value])

  const hasChanges = FormHelper.hasChanged([name, amount, values, comment])

  return <Box display="flex" flexDirection="column" sx={{flex: 1}}>

    <Box sx={{position: "absolute", bottom: 40+8, marginLeft: 2}} display="flex" alignItems="center">
      {hasChanges && (<React.Fragment>

        <Box sx={{width: 20, paddingLeft: "2px"}} display="flex" alignItems="center">
          <CircularProgress size={14} color="info" />
        </Box>
        <Typography sx={{marginLeft: 1, paddingTop: "2px"}} variant="caption" color="textSecondary">speichern..</Typography>
      </React.Fragment>)}
      {!hasChanges && (<React.Fragment>
        <CheckIcon color="info" fontSize="small" />
        <Typography sx={{marginLeft: 1, paddingTop: "2px"}} variant="caption" color="textSecondary">gespeichert</Typography>
      </React.Fragment>)}
    </Box>

    <Box sx={{flex: 1}} display="flex" paddingTop={1} >
      {step === 0 && (<ControllerAvailabilityStep schedule={props.schedule} name={name} values={values}  handleValuesChange={handleOptionsChange}/>)}
      {step === 1 && (<ControllerMorePeopleStep schedule={props.schedule} name={name} values={values} amount={amount} controls={peopleControls} onNext={handlePeopleNext}/>)}
      {step === 2 && (<ControllerCommentStep schedule={props.schedule} name={name} comment={comment} values={values} controls={commentControls} onNext={handleCommentsNext}/>)}
    </Box>

    <Box>
      <MobileStepper
          sx={{height: 40, borderRadius: 0}}
          variant="dots"
          steps={3}
          position="static"
          activeStep={step}
          nextButton={
            <IconButton size="small" onClick={()=>{
              setStep(step+1)
              setFinished(true)
            }} disabled={step === 2 || loading}>
              <KeyboardArrowRight fontSize="small"/>
            </IconButton>
          }
          backButton={
            <IconButton size="small" onClick={()=>{
              setStep(step-1)
              setFinished(true)
            }} disabled={step === 0 || loading}>
              <KeyboardArrowLeft fontSize="small"/>
            </IconButton>
          }
      />
    </Box>

  </Box>

}