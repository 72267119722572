import React from "react";
import {AsporComponent} from "@aspor/aspor-react";
import {Toolbar} from "@mui/material";

export type AppContentProps = {
    children?: any,
    disableOverflow?: boolean
}

export type AppContentState = {

}

export default class AppContent extends AsporComponent<AppContentProps, AppContentState>{

    render(){
        return <React.Fragment>
            <Toolbar variant="dense" />
            <div style={{width: "100%",height: "calc(100vh - 48px)", overflow: this.props.disableOverflow ? "hidden" : "overlay"}}>
                {this.props.children}
            </div>
        </React.Fragment>
    }

}
