
export interface Notification {
    text: string,
    variant: 'error' | 'success' | 'warning' | 'info';
}

export class NotificationService {

    private readonly messenger: (notification: Notification)=>void

    constructor(messenger: (notification: Notification)=>void) {
        this.messenger = messenger;
    }

    sendInfo(message: string){
        this.messenger({text: message, variant: "info"})
    }

    sendError(message: string){
        this.messenger({text: message, variant: "error"})
    }

    sendSuccess(message: string){
        this.messenger({text: message, variant: "success"})
    }

    sendWarning(message: string){
        this.messenger({text: message, variant: "warning"})
    }

    handleError = (error : any) => {
        if(error.message === "Network Error"){
            if(error.response){
                this.sendError("An error occurred while loading data - "+error.response.status)
            }else{
                this.sendError("Unable to execute action, please check your internet connection")
            }
        }else{
            this.sendError("An internal error occurred")
        }
    }

}
