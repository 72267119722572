import React, {useState} from 'react';
import {Box, Drawer, IconButton, Paper, useMediaQuery, useTheme} from "@mui/material";
import {Schedule} from "../../../entities";
import {useApplication, useService} from "@aspor/aspor-react";
import ControllerLogin from "./ControllerLogin";
import {UserService} from "../../../services/user/user.service";
import ControllerInside from "./ControllerInside";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

export type ScheduleControllerProps = {
  schedule?: Schedule
}

const drawerBleeding = 56;

export default function ScheduleController(props: ScheduleControllerProps) {
  const app = useApplication()
  const userService = useService(UserService)

  const [loggedIn, setLoggedIn] = useState<boolean>(()=>{
    return app.isAuthenticated || userService.getAnonymousName() != null
  })

  const [open, setOpen] = useState<boolean>(true);

  const handleClose = (e? : any) => {
      if(e) e.stopPropagation();
      if(loggedIn) setOpen(false)
  }

  const handleOpen = () => {
      if(!open) setOpen(true)
  }

  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  if(sm){
      return <React.Fragment>
          <Box sx={{height: drawerBleeding-8}}>

          </Box>
          <Drawer
              anchor="bottom"
              open={open}
              onClose={handleClose}
              sx={{"&> .MuiPaper-root":{
                      overflow: 'visible',
                      minHeight: 240-drawerBleeding,
                      maxHeight: 240-drawerBleeding,
              }}}
              ModalProps={{
                  keepMounted: true,
              }}
          >
              <Paper onClick={handleOpen}
                         sx={{
                             zIndex: 1,
                             minHeight: 240,
                             maxHeight: 240,
                             borderBottomLeftRadius: 0,
                             borderBottomRightRadius: 0,
                             boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
                             display: "flex",
                             flexDirection: "column",
                             position: 'absolute',
                             top: -drawerBleeding,
                             borderTopLeftRadius: 8,
                             borderTopRightRadius: 8,
                             visibility: 'visible',
                             right: 0,
                             left: 0,
                         }}>
                  {!loggedIn&& (<ControllerLogin onContinue={()=>setLoggedIn(true)} />)}
                  {loggedIn && (<ControllerInside schedule={props.schedule} onComplete={handleClose} />)}
              </Paper>
          </Drawer>
      </React.Fragment>
  }else{
      return <Box sx={{ transition: "height 0.2s ease-out", minHeight: open ? 240 : drawerBleeding, maxHeight: open ? 240 : drawerBleeding, position: "relative"}}>
          <Paper
              onClick={()=>setOpen(true)}

              sx={{
                  width: "100%",
                  transition: "bottom 0.2s ease-out",
                  bottom: open ? 0 : (240-drawerBleeding)*-1,
                  position: "absolute",
                  zIndex: 1,minHeight: 240, maxHeight: 240, borderBottomLeftRadius: 0, borderBottomRightRadius: 0,
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;", display: "flex", flexDirection: "column"}}
          >
              {!loggedIn&& (<ControllerLogin onContinue={()=>setLoggedIn(true)} />)}
              {loggedIn && (<ControllerInside schedule={props.schedule} onComplete={handleClose} />)}
              {open && loggedIn && (
                  <Box sx={{position: "absolute", right: 0, marginRight: 0.5, float: "right", marginTop: 0.5}}>
                      <IconButton size="small" onClick={handleClose}>
                          <ExpandCircleDownIcon />
                      </IconButton>
                  </Box>
              )}
          </Paper>
      </Box>
  }

}