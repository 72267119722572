import {AsporComponent} from "@aspor/aspor-react";
import {UserService} from "../services/user/user.service";

export type UserProps = {
    id?: string,
    tenantId?: string
}

export type UserState = {
    username: string
}

export default class User extends AsporComponent<UserProps, UserState>{

    state = {
        username: "Loading.."
    }

    componentDidMount() {
        if(this.props.id){
            this.app.service(UserService).getUser(this.props.id, this.props.tenantId).then((user)=>{
                this.setState({username: user.username+"#"+user.discriminator})
            })
        }
    }

    shouldComponentUpdate(nextProps: Readonly<UserProps>, nextState: Readonly<UserState>, nextContext: any): boolean {
        if(nextProps.id !== this.props.id && nextProps.id){
            this.app.service(UserService).getUser(nextProps.id, this.props.tenantId).then((user)=>{
                this.setState({username: user.username})
            })
            return false;
        }
        return true;
    }

    render(){
        return this.state.username;
    }
}
