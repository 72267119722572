import {Box, Container, Divider, IconButton, Skeleton, Typography, useMediaQuery, useTheme} from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import ScheduleContainer from "../../components/scheduler/ScheduleContainer";
import {FallbackAvatar, GlobalWindowTitleConfig} from "@fluxusui/fluxusui-base";
import {useApplication, useServiceODataEntityResponse} from "@aspor/aspor-react";
import {DataService} from "../../services/data/data.service";
import {useParams} from "react-router-dom";
import User from "../../components/User";
import React from "react";
import {UserService} from "../../services/user/user.service";

export type SchedulerRouteProps = {

}

export default function ScheduleRoute(props: SchedulerRouteProps){
    const app = useApplication()
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up("sm"));

    const {scheduleId} = useParams()

    const [schedule,,error] = useServiceODataEntityResponse(DataService
        ,(service)=>service.schedules(scheduleId!)
            .expandMany(s => s.options,s => s.orderBy(o => o.date))
            .get()
        ,[scheduleId])

    const handleShare = () => {
        navigator.share({title: document.title.replace(GlobalWindowTitleConfig.SUFFIX,""), url: window.location.href})
    }

    if(error){
        return <Container maxWidth="lg" sx={{height: "100%", padding: 0}}>
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <Typography color="error" fontWeight="bold" variant="subtitle1" >Kalender wurde nicht gefunden</Typography>
            </Box>
        </Container>
    }

    return <Container maxWidth="lg" sx={{height: "100%", padding: 0}}>

        <Box display="flex" sx={{height: "100%"}} flexDirection="column">

            <Box marginRight={sm?0:2} marginLeft={sm?0:2}>
                <Box marginTop={2} marginBottom={2} display="flex" alignItems="center" justifyContent="space-between" >
                    <Box>
                        <Typography variant="h6" fontWeight="bold" >
                            {schedule?schedule.name:<Skeleton />}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            {schedule ? <React.Fragment>
                                    <FallbackAvatar size={15} src={app.service(UserService).getAvatar(schedule.ownerId)}/>
                                    <Typography marginLeft={1} variant="caption"  >
                                        <User id={schedule.ownerId} />
                                    </Typography>
                                </React.Fragment>
                                : <Skeleton />}
                        </Box>
                    </Box>

                    <IconButton onClick={handleShare}>
                        <ShareIcon />
                    </IconButton>
                </Box>

                <Divider />
            </Box>

            <Box marginTop={2} sx={{flex: 1, height: 0}}>
                <ScheduleContainer schedule={schedule} />
            </Box>

        </Box>

    </Container>

}