import * as React from "react";
import {useApplication} from "@aspor/aspor-react";
import {
    Box,
    Typography
} from "@mui/material";
import {ControlledPopover, FallbackAvatar, FlexBox, OverflowTypography} from "@fluxusui/fluxusui-base";
import {ScheduleEntry} from "../entities";
import {UserService} from "../services/user/user.service";
import {PopoverState} from "@fluxusui/fluxusui-base/hooks/usePopoverHook";

interface InfoPopoverProps {
    state: PopoverState,
    entry: ScheduleEntry
}

export default function InfoPopover(props : InfoPopoverProps){
    const app = useApplication()
    const userService = app.service(UserService)

    const anonymous = props.entry.userId === null;
    const amount = props.entry.amount;

    return (
        <ControlledPopover state={props.state}
                           anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                           transformOrigin={{vertical: 'center', horizontal: 'right'}}>

            <FlexBox padding={1} flexDirection="column" width={200}>
                <Box display="flex" alignItems="center">
                    {anonymous && (<FallbackAvatar size={30} name={props.entry.userName} src={props.entry.userPictureUrl} />)}
                    {!anonymous && (<FallbackAvatar size={30} src={userService.getAvatar(props.entry.userId)} />)}
                    <OverflowTypography marginLeft={1} variant="subtitle1" fontWeight="bold">{props.entry.userName}</OverflowTypography>
                    {amount > 1 && (<Typography marginLeft={0.5} variant="body2" sx={{color: "#0288d1"}} >({amount})</Typography>)}
                </Box>
                <Box marginTop={1}>
                    <Typography variant="body2">{(props.entry.comment?.length??0) > 0 ? props.entry.comment : "Kein Kommentar"}</Typography>
                </Box>
            </FlexBox>

        </ControlledPopover>
    )
}
